<template>
	<div style="width:100%;">
		<!-- <button @click="start" class="button col button-fill button-large voice-btn" :style="btn_not_pressed_img" ref="btnRef">
		  {{ $t('public.voiceInput') }}
		</button> -->
		<div class="mubu" @touchstart="start" @touchend="end" @touchcancel="end">
			
		</div>
		<button id="voiceBtn" class="button col button-fill button-large voice-btn" :style="btn_not_pressed_img" ref="btnRef">
		  {{ $t('public.voiceInput') }}
		</button>
		<!-- <button @click="end" class="button col button-fill button-large voice-btn" :style="btn_not_pressed_img" ref="btnRef">
		  {{ $t('public.voiceEnd') }}
		</button> -->
	</div>
	
</template>

<script>
	let webAudioSpeechRecognizer;
	let isCanStop;
	const params = {
	  signCallback: signCallback, // 鉴权函数
	  // 用户参数
	  secretid:  config.secretId,
	  appid: config.appId,
	  // 实时识别接口参数
	  engine_model_type : '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
	  // 以下为非必填参数，可跟据业务自行修改
	  voice_format : 1,
	  hotword_id : '08003a00000000000000000000000000',
	  needvad: 1,
	  filter_dirty: 1,
	  filter_modal: 2,
	  filter_punc: 0,
	  convert_num_mode : 1,
	  word_info: 2
	}
	export default {
		name: "Record—Page",
		data() {
			return {
				isRecording: false,
				isUploading: false,
				isUploadSuccess: false,
				btn_not_pressed_img: "background-color: #b51414",
				btn_pressed_img: "background-color: #ed872e",
				text: '识别',
			}
		},
		mounted() {
				
			this.init();
			// this.start();
			// let that = this;
			// setTimeout(function(){
			// 	that.end()
			// },500)
			// const box = document.getElementById('voiceBtn');
			// box.addEventListener('touchstart',function(event){
			// 	event.preventDefault()
			// 	that.btn_not_pressed_img = "background-color: #ed872e";
			// 	that.start()
			// })
			// box.addEventListener('touchend',function(event){
			// 	event.preventDefault()
			// 	that.btn_not_pressed_img = "background-color: #b51414";
			// 	if(webAudioSpeechRecognizer){
			// 		webAudioSpeechRecognizer.stop();
			// 	}
			// })
		},
 
		beforeDestroy() {
			console.log("before destroy");
			try {
				if (isCanStop) {
				  webAudioSpeechRecognizer.stop();
				}
			} catch (e) {
 
			}
		},
 
		methods: {
			init() {
				this.isRecordReady = false;
				this.isRecording = false;
				this.isUploading = false;
				this.isUploadSuccess = false;
			},
		    start(event){
				if(event){
					event.preventDefault()
					this.btn_not_pressed_img = "background-color: #ed872e";
				}
					
				
				let _this = this;
				webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
				_this.text = "";
				let resultText = '';
				// 开始识别
				webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
				  console.log('开始识别', res);
				  isCanStop = true;
				};
				// 一句话开始
				webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
				  console.log('一句话开始', res);
				  isCanStop = true;
				};
				// 识别变化时
				webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
				  console.log('识别变化时', res);
				  const currentText = `${resultText}${res.result.voice_text_str}`;
				  _this.text = currentText.replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、]/g,'');
				  _this.$emit("childEvent",_this.text);
				};
				// 一句话结束
				webAudioSpeechRecognizer.OnSentenceEnd = (res) => {
				  console.log('一句话结束', res);
				  resultText += res.result.voice_text_str;
				  _this.text = resultText.replace(/[`:_.~!@#$%^&*() \+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*（）—— \+ ={}|《》？：“”【】、；‘’，。、]/g,'');
				  _this.$emit("childEvent",_this.text);
				};
				// 识别结束
				webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
				  console.log('识别结束', res);
				};
				// 识别错误
				webAudioSpeechRecognizer.OnError = (res) => {
				  console.log('识别失败', res)
				};
				webAudioSpeechRecognizer.start();
			},
			end(event){
				if(event){
					event.preventDefault()
					this.btn_not_pressed_img = "background-color: #b51414";
				}
					
				
				// if (isCanStop) {
				//   webAudioSpeechRecognizer.stop();
				// }
				if (webAudioSpeechRecognizer) {
				  webAudioSpeechRecognizer.stop();
				}
			}
		}
	}
</script>

<style scoped>
	.voice-btn{
		width: 100%;
		background: #b51414;
		border: 0;
		color: #fff;
		font-size: 1rem;
		padding: 0.5rem 0;
		border-radius: 0.5rem;
		margin: 0.5rem 0;
		text-align: center;
		cursor: pointer;
		user-select:none;
		-webkit-touch-callout: none !important;
		-webkit-user-select: none !important;
		-khtml-user-select: none !important;
		-moz-user-select:none !important;
		-ms-user-select:none !important;
	}
	.voice-btn:active{
		background: #ed872e !important;
	}
	.mubu{
		width: 100%;
		height: 55px;
		position: absolute;
		z-index: 1;
	}
</style>
