<template>
  <div class="voice-play-box" style="display: none">
    <audio :src="audioSrc" autoplay></audio>
  </div>
</template>

<script>
import BASE from "@/utils/base";
export default {
  name: "VoicePlay",
  components: {},
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      audioSrc: "",
    };
  },
  watch: {
    content(newV) {
      console.log("VoicePlay", newV);
      if (newV === "") return;
      const that = this;
      this.axios
        .post(
          BASE.genUrl("zxSuperviseComm/txt2Voice", {
            msg: encodeURIComponent(newV),
          })
        )
        .then(function (res) {
          console.log(res.data.result, "11111");
          that.audioSrc = res.data.result;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
}
</style>
